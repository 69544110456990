<template>
  <div>
    <b-button @click="$router.push('/configuracoes/tipo_perfil')">Voltar</b-button>
    <Card class="py-3 mt-2">
      <b-row>
        <b-col>
          <label>Tipo</label>
          <b-input v-model="model.type"></b-input>
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button v-if="!editing" @click="save_profile_type">Criar</b-button>
        <b-button v-else @click="update_profile_type">Alterar</b-button>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing: false,
      model: {
        company_id: "",
        unity_id: this.$store.state.auth.currentUser.unity_id,
        type: "",
        group_id: "",
        active: "1",
      },
    };
  },
  methods: {
    async save_profile_type() {
      await this.$store.dispatch("configurations/save_profile_type", this.model);
      this.$router.push("/configuracoes/tipo_perfil");
    },
    async update_profile_type() {
      await this.$store.dispatch(
        "configurations/update_profile_type",
        this.model
      );
      this.$router.push("/configuracoes/tipo_perfil");
    },
  },
  async mounted() {
    const profileTypeId = this.$route.params.profileTypeId;
    if (profileTypeId) {
      const profileType = await this.$store.dispatch(
        "configurations/get_profile_types_by_id",
        profileTypeId
      );
      console.log(profileType);
      this.model = profileType[0];
      this.editing = true;
    }
  },
};
</script>

<style>
</style>